.expander section > :last-child {
  margin-bottom: 0;
}
.attachments hr {
  margin: 0.5rem 0;
}

@media (min-width: 950px) {
  .up2 {
    display: grid;
    gap: 1rem;
    margin: 0;
    grid-template-columns: 55fr 45fr;
  }
  .up2.artwork-details > :last-child {
    padding-left: 1rem;
    border-left: 1px solid var(--dark);
  }
}

.pill {
  display: inline-block;
  white-space: nowrap;
  font-size: 13px;
  padding: 0.3em 0.8em;
  margin-top: 0.5em;
  margin-right: 0.5em;
  border-radius: 0.3em;
  background: var(--lite);
}
.orange {
  color: #e07000;
  background: #ffebd6;
}
.framed {
  color: #6a69ce;
  background: #d6d6ff;
}
.pink {
  color: #cd6363;
}
.green {
  color: #538d7b;
  background: #d2ede6;
}
.tint {
  color: white;
  background: rgba(0, 0, 0, 0.5);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row > * {
  margin-top: 0;
}
.row > * + :last-child {
  text-align: right;
}

.group > * {
  display: inline-block;
  vertical-align: middle;
}
.group > * + * {
  margin-left: 0.5em;
}

@media (max-width: 499px) {
  .bar .fat {
    width: 100%;
    display: block;
  }
  .bar .commit {
    margin: 0.5rem 0 0 0;
  }
}

.labeledField * {
  text-align: left;
}
.labeledField:has(.pills) {
  padding-bottom: 2em;
}

@media (min-width: 500px) {
  .edit > div {
    display: flex;
    max-width: 50em;
  }
  .edit > div > div {
    width: 100%;
  }
  .buttons {
    margin-left: 16ch;
  }
}
.edit .radios {
  align-items: center;
}
.edit .radios label {
  margin: 0;
}
dialog .radios > * > * {
  display: block;
}
label {
  display: block;
  flex-shrink: 0;
  margin: 0.6em 0;
  min-width: 16ch;
}

.iconText :first-child {
  margin-right: 0.5em;
}
.imageLeft {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 16px;
  text-align: left;
}
tbody tr {
  border-top: 1px solid var(--lite);
}
tr:nth-child(odd) {
  background: #f8f9fa;
}
th {
  white-space: nowrap;
  background: var(--pale);
}

.bubble {
  padding: 1rem;
  overflow: hidden;
  background: var(--pale);
  border-radius: 10px;
}

.tiles {
  display: grid;
  gap: 1rem;
}
@media (min-width: 400px) {
  .tiles {
    grid-template-columns: repeat(auto-fill, minmax(calc(400px - 2rem), 1fr));
  }
}

.cards {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}
.card,
.cards li {
  background: white;
  position: relative;
  border-radius: var(--curvy);
  border: 1px solid var(--pale);
  box-shadow: 3px 3px 18px 0px #0000000d;
}
.cards li {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.cards img {
  border-radius: var(--curvy) var(--curvy) 0 0;
}
.cards li > * {
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}
.cards li {
  padding-bottom: 0.5rem;
}
.cards li > :first-child {
  padding: 0;
  margin-bottom: 1rem;
}

.field textarea {
  height: 160px;
}
.field input,
.field select,
.field textarea,
.field .file {
  width: 100%;
  padding: 0.6em;
}
.multi {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  padding: 0.2em;
}
.multi > * {
  display: flex;
  padding: 0.2em 1em;
  margin: 0.2em;
  font-size: 1em;
}
.multi > input {
  padding: 0.2em 0.4em;
  width: 0;
  min-width: 10ch;
  flex-grow: 1;
}
.menu {
  padding: 0;
  overflow: hidden;
}
.menu a {
  display: block;
  padding: 0.4em 1em;
}
.menu.field a {
  color: var(--inactive);
}
.menu a:hover {
  background: var(--lite);
}
input[readonly] {
  background: var(--lite);
}
input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
input[type="checkbox"]:checked::after {
  transform: rotate(45deg);
  content: " ";
  width: 30%;
  height: 60%;
  position: absolute;
  top: 5%;
  left: 30%;
  border-right: solid var(--accent) 2px;
  border-bottom: solid var(--accent) 2px;
}
input[type="checkbox"],
input[type="radio"] {
  padding: 0.6em;
  margin-right: 0.3em;
  width: auto;
  height: 0;
  position: relative;
  vertical-align: middle;
}
input[type="radio"] {
  border-radius: 100%;
}
input[type="radio"]:checked {
  background-color: var(--accent);
}
input[type="radio"]:checked::after {
  width: 60%;
  height: 60%;
  content: " ";
  top: 20%;
  left: 20%;
  position: absolute;
  background-color: var(--bg);
  border-radius: 100%;
}

/* select {
  background-repeat: no-repeat;
  background-image:
    linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1.5em + 2px),
    calc(100% - 15px) calc(1.5em + 2px);
  background-size:
    5px 5px,
    5px 5px;
} */

.line1 {
  -webkit-line-clamp: 2;
}
.line1,
.line2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

[class^="arrow"] {
  width: 0;
  height: 0;
}
.arrow-up {
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid currentColor;
}
.arrow-down {
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid currentColor;
}

.spinner {
  display: inline-block;
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
  border: 0.2em solid currentColor;
  /* margin: 0 .5em;
   */
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
button .spinner {
  margin-top: -0.15em;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
    opacity: 0.4;
  }
  50% {
    transform: rotate(180deg);
    opacity: 1;
  }
  to {
    transform: rotate(360deg);
    opacity: 0.4;
  }
}

.ownership {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--dark);
}

dialog {
  position: relative;
  display: block;
  text-align: center;
  width: 45ch;
  max-width: calc(100vw - 2rem);
  padding: 1rem;
  padding-bottom: 0;
}
dialog * * {
  text-align: left;
}
dialog > * {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

.pictures:not(:empty) {
  display: grid;
  gap: 0.5rem;
  margin: 0.5rem 0;
  grid-template-columns: repeat(auto-fill, minmax(4rem, 1fr));
  /* grid-template-columns: repeat(auto-fit, 5rem); */
  /* grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column; */
}
.pictures > * {
  position: relative;
}

main .avatar {
  font-size: 18px;
}

.details {
  display: grid;
  grid: auto / auto 1fr;
  gap: 0.5rem 0.5em;
  line-height: 1.4;
}
