#app {
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  display: grid;
  grid-template: "side header" auto "side main" 1fr / auto 1fr;
}
header {
  grid-area: header;
}
main {
  grid-area: main;
  overflow-y: auto;
}
#side {
  min-width: 8rem;
  grid-area: side;
  transition: transform 200ms ease-out;
}
#side > * {
  display: block;
  line-height: 2;
  padding: 0 1rem;
  margin: 0.8em 0;
}
#side .logo {
  color: white;
  margin: 1rem 0;
}
#side .active:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 100%;
  background: currentColor;
  border-radius: 0px 4px 4px 0px;
}

.hasBar {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}
.hasBar > :first-child {
  overflow-y: auto;
}

@media (max-width: 800px) {
  #app {
    grid-template: "header" auto "main" 1fr / 1fr;
  }
  #side {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100vh;
  }
  #app:not(.menuOn) #side {
    transform: translateX(-100%);
  }
}
@media (min-width: 801px) {
  #bottomBar,
  header .icon-menu {
    display: none !important;
  }
}

.nav {
  color: rgba(255, 255, 255, 0.6);
  background: #282729;
}
.nav .active {
  color: var(--pink);
  position: relative;
}

.bar {
  z-index: 1;
  position: relative;
  padding: 0.5rem;
  box-shadow: var(--card);
}
