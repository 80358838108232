:root {
  --bg: #fff;
  --card: 0 0 0.5em rgba(0, 0, 0, 0.15);
  --inactive: #858e96;
  --dark: #ced4da;
  --lite: #e9ecef;
  --pale: #f1f3f5;
  --blue: #1f72a7;
  --accent: #38c976;
  --pink: #e5979c;
  --error: #d32c12;
  --curvy: 8px;
}
* {
  color: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  text-decoration: none;
  background: none;
  box-sizing: border-box;
}
/* Gutter = 1 rem: */
html {
  font-size: 25px;
}
@media (min-width: 600px) {
  html {
    font-size: 30px;
  }
  [href]:hover img {
    transform: scale(1.04);
  }
  [href]:hover {
    text-decoration: underline;
  }
  [href]:hover:has(svg, strong) {
    text-decoration: none;
  }
  section [href]:hover {
    color: var(--blue);
  }
}
img {
  display: block;
  width: 100%;
  height: auto;
  background: var(--lite);
  border-radius: var(--curvy);
  transition: transform 200ms ease-out;
}

section {
  padding: 1rem;
  padding-bottom: 0 !important;
}
section > * {
  display: block;
  margin-bottom: 1rem;
}
section section {
  padding: 0;
}
li {
  margin: 0.5rem;
}

hr {
  border-bottom: 1px solid var(--dark);
}
body,
::placeholder {
  color: #69686d;
}
body {
  font: 15px/1.6 "Ubuntu", sans-serif;
}
button,
input,
select,
textarea {
  appearance: none;
  font: inherit;
  resize: none;
}
button[disabled],
form:invalid [type="submit"] {
  opacity: 0.5;
}
.fat,
strong,
label,
h1,
h2,
h3,
th {
  font-weight: 500;
  color: #1e1e1e;
}

svg {
  vertical-align: middle;
  display: inline-block;
  width: auto;
  height: 1.5em;
}

h1 {
  font-size: 0.8rem; /* 25px */
  line-height: 1.25;
}
h2 {
  font-size: 0.7rem; /* 20px */
}
h3 {
  font-size: 0.6rem; /* 18px */
}
small,
.sub {
  font-size: 0.8em; /* 13px */
}

.blue {
  color: var(--blue);
}

.fat {
  text-align: center;
  white-space: nowrap;
  min-width: 13ch;
  padding: 0.6em 1.5em;
}
.fat,
.field {
  background: white;
  border-radius: 4px;
  transition: box-shadow 100ms;
  box-shadow: 0 0 0 1px var(--dark) inset;
}
.field:has(:focus) {
  box-shadow: 0 0 0 1px var(--blue) inset;
}
.field:has(.invalid, :invalid),
.field.invalid {
  box-shadow: 0 0 0 1px var(--error) inset;
}
.commit {
  color: white;
  background: #231f20;
  box-shadow: none;
}
